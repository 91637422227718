<template>
  <div
    :class="{
      search: true,
      active: isOpen && isMobile,
    }"
  >
    <button
      class="button search-button"
      data-js="search-button"
      @click="toggleSearch"
    >
      <svg
        class="icon-themable"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          fill="#7E7E7E"
          fill-rule="evenodd"
          d="M1.116 7.628a6.512 6.512 0 1 0 13.023 0 6.512 6.512 0 0 0-13.023 0Zm6.512 7.628a7.628 7.628 0 1 1 5.774-2.643l2.434 2.434a.558.558 0 1 1-.789.79l-2.434-2.435a7.598 7.598 0 0 1-4.985 1.854Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <div class="search-input-container">
      <input
        ref="searchInput"
        data-js="search-input"
        class="search-input"
        name="search"
        :placeholder="initialData.translations.searchPlaceholder"
        v-model="query"
        @click="toggleSearch"
      />
      <svg
        class="icon-themable search-input-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        @click="this.search"
      >
        <path
          fill="#7E7E7E"
          fill-rule="evenodd"
          d="M1.116 7.628a6.512 6.512 0 1 0 13.023 0 6.512 6.512 0 0 0-13.023 0Zm6.512 7.628a7.628 7.628 0 1 1 5.774-2.643l2.434 2.434a.558.558 0 1 1-.789.79l-2.434-2.435a7.598 7.598 0 0 1-4.985 1.854Z"
          clip-rule="evenodd"
        />
      </svg>
      <button
        v-if="query.length"
        class="button clear-button"
        data-js="clear-button"
        @click="query = ''"
      >
        <svg
          class="clear-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="16"
          fill="none"
        >
          <path
            class="clear-path"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.8"
            d="m14.285 3-10 10m0-10 10 10"
          />
        </svg>
      </button>
    </div>
    <div
      :class="{
        'search-dropdown modal': true,
        'active search-dropdown-desktop': isOpen,
      }"
    >
      <div class="search-mobile-menu">
        <div class="search-mobile-menu-top">
          <button
            class="button button-small search-mobile-filter-button"
            tabindex="0"
            @click="toggleFilterOpen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="none"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M20.855 6.417c0 .38-.308.687-.688.687H1.834a.688.688 0 0 1 0-1.375h18.333c.38 0 .688.308.688.688ZM18.105 11c0 .38-.308.688-.688.688H4.584a.687.687 0 1 1 0-1.376h12.833c.38 0 .688.308.688.688ZM15.354 15.583c0 .38-.307.688-.687.688H7.334a.687.687 0 1 1 0-1.375h7.333c.38 0 .688.308.688.687Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          :class="{
            'search-mobile-filter': true,
            active: isMobile && this.filter.isOpen,
          }"
        >
          <span class="filter-content-title">{{
            initialData.translations.products
          }}</span>
          <ul class="filter-list">
            <li
              v-for="(product, i) in products"
              v-bind:key="product.slug + i"
              :id="product.slug + i"
              class="filter-list-item"
            >
              <label tabindex="0" class="checkbox">
                {{ product.title }}
                <input
                  :id="product.slug + '-mobile'"
                  :name="product.slug"
                  type="checkbox"
                  :checked="filter.selectedProducts.includes(product.slug)"
                  @change="toggleProductSelection(product.slug)"
                />
                <span class="checkmark"></span>
              </label>
              <div
                v-if="
                  (product.versions && product.versions.length) ||
                  (product.platforms && product.platforms.length)
                "
                class="select"
              >
                <button class="button select-button dropdown-item">
                  <span class="select-text">{{
                    product.selectedOption ||
                    (product.versions && product.versions[0].text) ||
                    (product.platforms && product.platforms[0].text)
                  }}</span>
                  <svg
                    class="icon-themable select-icon"
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.21166 5.39697L7.88577 1.25292C8.11503 0.994336 7.97606 0.5 7.67411 0.5L0.325888 0.5C0.0239361 0.5 -0.115032 0.994336 0.114227 1.25292L3.78834 5.39697C3.91014 5.53434 4.08986 5.53434 4.21166 5.39697Z"
                      fill="#000"
                    ></path>
                  </svg>
                </button>
                <ul class="select-options">
                  <li
                    v-for="(version, i) in product.versions"
                    :key="version + i"
                    class="select-option"
                    tabindex="0"
                    @click="selectVersionOrPlatform(product.slug, version)"
                  >
                    {{ version.text }}
                  </li>
                  <li
                    v-for="(platform, i) in product.platforms"
                    :key="platform + i"
                    class="select-option"
                    tabindex="0"
                    @click="selectVersionOrPlatform(product.slug, platform)"
                  >
                    {{ platform.text }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="search-mobile-buttons">
            <button
              class="button search-mobile-button"
              @click="this.resetFilter"
            >
              {{ initialData.translations.resetFilter }}
            </button>
            <button
              class="button search-mobile-button"
              @click="this.applyFilter"
            >
              {{ initialData.translations.apply }}
            </button>
          </div>
        </div>
      </div>
      <div class="search-dropdown-content">
        <aside
          :class="{
            'filter toggleable-content': true,
            closed: !this.filter.isOpen,
          }"
        >
          <div class="filter-header">
            <button class="button button-filter">
              <svg
                class="icon-themable"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="none"
              >
                <path
                  fill="#1D1D1F"
                  fill-rule="evenodd"
                  d="M20.855 6.417c0 .38-.308.687-.688.687H1.834a.688.688 0 0 1 0-1.375h18.333c.38 0 .688.308.688.688ZM18.105 11c0 .38-.308.688-.688.688H4.584a.687.687 0 1 1 0-1.376h12.833c.38 0 .688.308.688.688ZM15.354 15.583c0 .38-.307.688-.687.688H7.334a.687.687 0 1 1 0-1.375h7.333c.38 0 .688.308.688.687Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <h4 class="filter-title">{{ initialData.translations.filter }}</h4>
            <button
              class="button button-small button-toggle"
              @click="toggleFilterOpen"
            >
              <svg
                class="icon-themable"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M10.444 2.25c-1.838 0-3.294 0-4.433.153-1.172.158-2.121.49-2.87 1.238-.748.749-1.08 1.698-1.238 2.87-.153 1.14-.153 2.595-.153 4.433v2.112c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.433.153h5.022a.768.768 0 0 0 .072-.001c1.384-.004 2.523-.027 3.451-.152 1.172-.158 2.121-.49 2.87-1.238.748-.749 1.08-1.698 1.238-2.87.153-1.14.153-2.595.153-4.433v-2.112c0-1.838 0-3.294-.153-4.433-.158-1.172-.49-2.121-1.238-2.87-.749-.748-1.698-1.08-2.87-1.238-.928-.125-2.067-.148-3.45-.152a.763.763 0 0 0-.073 0l-.91-.001h-4.112Zm4.306 1.5H10.5c-1.907 0-3.261.002-4.29.14-1.005.135-1.585.389-2.008.812-.423.423-.677 1.003-.812 2.009-.138 1.028-.14 2.382-.14 4.289v2c0 1.907.002 3.262.14 4.29.135 1.005.389 1.585.812 2.008.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.289.14h4.25V3.75Zm1.5 16.494c1.034-.01 1.858-.042 2.54-.134 1.005-.135 1.585-.389 2.008-.812.423-.423.677-1.003.812-2.009.138-1.027.14-2.382.14-4.289v-2c0-1.907-.002-3.261-.14-4.29-.135-1.005-.389-1.585-.812-2.008-.423-.423-1.003-.677-2.009-.812-.68-.092-1.505-.123-2.539-.134v16.488Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            :class="{
              'filter-content': true,
              opened: !filter.isShort,
            }"
          >
            <h5 class="filter-content-title">
              {{ initialData.translations.products }}
            </h5>
            <ul class="filter-list filter-list-desktop">
              <li
                v-for="(product, i) in products"
                v-bind:key="product.slug + i"
                :id="product.slug + i"
                class="filter-list-item"
              >
                <label tabindex="0" class="checkbox">
                  {{ product.title }}
                  <input
                    :id="product.slug + '-desktop'"
                    :name="product.slug"
                    type="checkbox"
                    :checked="filter.selectedProducts.includes(product.slug)"
                    @change="toggleProductSelection(product.slug)"
                  />
                  <span class="checkmark"></span>
                </label>
                <div
                  v-if="
                    (product.versions && product.versions.length) ||
                    (product.platforms && product.platforms.length)
                  "
                  class="select"
                >
                  <button class="button select-button">
                    <span class="select-text">{{
                      product.selectedOption ||
                      (product.versions && product.versions[0].text) ||
                      (product.platforms && product.platforms[0].text)
                    }}</span>
                    <svg
                      class="icon-themable select-icon"
                      width="8"
                      height="6"
                      viewBox="0 0 8 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.21166 5.39697L7.88577 1.25292C8.11503 0.994336 7.97606 0.5 7.67411 0.5L0.325888 0.5C0.0239361 0.5 -0.115032 0.994336 0.114227 1.25292L3.78834 5.39697C3.91014 5.53434 4.08986 5.53434 4.21166 5.39697Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </button>
                  <ul class="select-options">
                    <li
                      v-for="(version, i) in product.versions"
                      :key="version + i"
                      class="select-option"
                      tabindex="0"
                      @click="selectVersionOrPlatform(product.slug, version)"
                    >
                      {{ version.text }}
                    </li>
                    <li
                      v-for="(platform, i) in product.platforms"
                      :key="platform + i"
                      class="select-option"
                      tabindex="0"
                      @click="selectVersionOrPlatform(product.slug, platform)"
                    >
                      {{ platform.text }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div class="button-nav-wrapper">
              <button
                v-if="products.length >= 6"
                class="button button-nav button-nav-more"
                tabindex="0"
                @click="toggleFilterHeight"
              >
                {{ initialData.translations.seeMore }}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-themable button-nav-icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.5119 8.43056C11.7928 8.18981 12.2072 8.18981 12.4881 8.43056L19.4881 14.4306C19.8026 14.7001 19.839 15.1736 19.5695 15.4881C19.2999 15.8026 18.8264 15.839 18.5119 15.5694L12 9.98781L5.48811 15.5694C5.17361 15.839 4.70014 15.8026 4.43057 15.4881C4.161 15.1736 4.19743 14.7001 4.51192 14.4306L11.5119 8.43056Z"
                    fill="#1D1D1F"
                  />
                </svg>
              </button>
              <button
                class="button button-nav button-nav-hide"
                tabindex="0"
                @click="toggleFilterHeight"
              >
                {{ initialData.translations.hide }}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-themable button-nav-icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.5119 8.43056C11.7928 8.18981 12.2072 8.18981 12.4881 8.43056L19.4881 14.4306C19.8026 14.7001 19.839 15.1736 19.5695 15.4881C19.2999 15.8026 18.8264 15.839 18.5119 15.5694L12 9.98781L5.48811 15.5694C5.17361 15.839 4.70014 15.8026 4.43057 15.4881C4.161 15.1736 4.19743 14.7001 4.51192 14.4306L11.5119 8.43056Z"
                    fill="#1D1D1F"
                  />
                </svg>
              </button>
            </div>
          </div>
        </aside>
        <div class="search-results search-results-wrapper">
          <div v-if="results.length === 0" class="search-results-start-wrapper">
            <img
              class="search-results-start-image"
              src="/assets/images/search.svg"
              alt="Search Icon"
            />
            <p>{{ initialData.translations.startTyping }}</p>
          </div>
          <h4 v-if="results.length !== 0" class="search-results-title">
            {{ initialData.translations.results }}: {{ pagination.totalItems }}
          </h4>
          <div v-if="results.length !== 0" class="search-results-cards">
            <a
              v-for="(result, i) in results"
              :key="result.title + i"
              :href="result.url"
              class="search-results-card"
              tabindex="0"
              :id="result.id"
            >
              <h4>{{ result.title }}</h4>
              <p class="search-results-card-text" v-html="result.content"></p>
              <div class="search-results-card-tags">
                <span
                  v-for="(tag, i) in result.tags"
                  :key="tag + i"
                  class="search-results-card-tag"
                  >{{ tag }}</span
                >
              </div>
            </a>
            <div :class="{ spinner: true, active: isLoading }"></div>
          </div>
        </div>
      </div>
      <div class="search-dropdown-footer">
        <p
          class="search-contact"
          v-html="initialData.translations.contactUs"
        ></p>
        <div class="search-info">
          <svg
            class="icon-themable"
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="10"
            fill="none"
          >
            <path
              fill="#1D1D1F"
              fill-rule="evenodd"
              d="M2.72.47a.75.75 0 0 1 1.06 0l2.5 2.5a.75.75 0 0 1-1.06 1.06L4 2.81V9a.75.75 0 0 1-1.5 0V2.81L1.28 4.03A.75.75 0 0 1 .22 2.97l2.5-2.5ZM26.28 9.53a.75.75 0 0 1-1.06 0l-2.5-2.5a.75.75 0 0 1 1.06-1.06L25 7.19V1a.75.75 0 0 1 1.5 0v6.19l1.22-1.22a.75.75 0 1 1 1.06 1.06l-2.5 2.5Z"
              clip-rule="evenodd"
            />
          </svg>
          <span>{{ initialData.translations.navigate }}</span>
          <span class="search-escape">esc</span>
          <span>{{ initialData.translations.close }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./SearchComponent.js"></script>
<style lang="scss" src="./SearchComponent.scss"></style>
