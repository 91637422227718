import { EventBus, EVENT_BUS } from './eventBus';

export const EventsModule = (function () {
  let isSearchOpen = false;
  let isMenuOpen = false;
  const overlay = document.querySelector('.overlay');
  const headerOverlay = document.querySelector('.header-overlay');

  function openSearchHandler() {
    if (!isSearchOpen) {
      handleBodyClassChange({ action: 'toggle', className: 'no-scroll' });
      overlay.classList.add('active');
      headerOverlay.classList.add('active');
      isSearchOpen = true;

      EventBus.emit(EVENT_BUS.EVENTS.CLOSE_HEADER_MENU);
    }
  }
  function closeSearchHandler() {
    if (isSearchOpen) {
      handleBodyClassChange({ action: 'toggle', className: 'no-scroll' });
      overlay.classList.remove('active');
      headerOverlay.classList.remove('active');
      isSearchOpen = false;
    }
  }

  function openHeaderMenuHandler() {
    if (!isMenuOpen) {
      handleBodyClassChange({ action: 'add', className: 'no-scroll' });
      overlay.classList.toggle('active');
      EventBus.emit(EVENT_BUS.EVENTS.CLOSE_SEARCH);
      isMenuOpen = true;
    }
  }
  function closeHeaderMenuHandler() {
    if (isMenuOpen) {
      handleBodyClassChange({ action: 'remove', className: 'no-scroll' });
      overlay.classList.remove('active');

      isMenuOpen = false;
    }
  }

  function handleBodyClassChange({ action, className }) {
    if (action === 'add') {
      document.body.classList.add(className);
    } else if (action === 'remove') {
      document.body.classList.remove(className);
    } else if (action === 'toggle') {
      document.body.classList.toggle(className);
    }
  }

  function initializeEventListeners() {
    EventBus.on(EVENT_BUS.EVENTS.CLOSE_SEARCH, closeSearchHandler);
    EventBus.on(EVENT_BUS.EVENTS.OPEN_SEARCH, openSearchHandler);
    EventBus.on(EVENT_BUS.EVENTS.OPEN_HEADER_MENU, openHeaderMenuHandler);
    EventBus.on(EVENT_BUS.EVENTS.CLOSE_HEADER_MENU, closeHeaderMenuHandler);
  }

  return {
    init: initializeEventListeners,
  };
})();
