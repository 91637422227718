export const ClipboardHandlerModule = (function () {
  function showCopiedMessage(messageElement) {
    messageElement.style.opacity = '1';
    messageElement.style.display = 'block';
    setTimeout(() => {
      messageElement.style.opacity = '0';
      messageElement.style.display = 'none';
    }, 2000);
  }

  function copyToClipboard(content, messageElement) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        showCopiedMessage(messageElement);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  function handleCodeBlockCopy(e) {
    const copyButton = e.target.closest('.copy-button');
    if (copyButton && copyButton.closest('.code-block-wrapper')) {
      const codeBlock = copyButton.closest('.code-block-wrapper');
      const code = codeBlock.querySelector('code').innerText;
      const message = codeBlock.querySelector('.copied-message');
      copyToClipboard(code, message);
    }
  }
  function handleAnchorCopy() {
    document.querySelectorAll('.copy-anchor-button').forEach((button) => {
      button.addEventListener('click', getShortLink);
    });
  }
  async function getShortLink(e) {
    const copyButton = e.target.closest('.copy-anchor-button');
    if (!copyButton) return;

    const fullUrl =
      window.location.origin +
      window.location.pathname +
      copyButton.getAttribute('data-link');

    const message = copyButton
      .closest('.heading-container')
      .querySelector('.copied-message');

    try {
      const response = await fetch('/v1/api/shorten', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fullUrl }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate short URL');
      }

      const data = await response.json();
      const shortUrl = data.shortUrl;

      copyToClipboard(shortUrl, message);
    } catch (error) {
      message.textContent = 'Error copying link';
      showCopiedMessage(message);
    }
  }

  function init() {
    document.addEventListener('click', handleCodeBlockCopy);
    handleAnchorCopy();
  }

  return {
    init,
  };
})();
