import { EventBus, EVENT_BUS } from './eventBus.js';
// Menu Dropdown Module
export const DropdownModule = (function () {
  const burgerButton = document.querySelector('.button-burger');
  const headerDropdown = document.querySelector('.header-dropdown');
  const menuItems = document.querySelectorAll('.accordeon-link');
  const toggleableDocumentMenu = document.querySelector(
    '.document .toggleable-content',
  );

  function toggleDropdown() {
    const isDropdownOpen = headerDropdown.classList.contains(
      'header-dropdown-opened',
    );

    if (isDropdownOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }

  function openDropdown() {
    headerDropdown.classList.add('header-dropdown-opened');
    burgerButton.classList.add('active');
    EventBus.emit(EVENT_BUS.EVENTS.OPEN_HEADER_MENU);
  }

  function closeDropdown() {
    headerDropdown.classList.remove('header-dropdown-opened');
    burgerButton.classList.remove('active');
    EventBus.emit(EVENT_BUS.EVENTS.CLOSE_HEADER_MENU);
  }

  function onBurgerClick(event) {
    event.stopPropagation();
    toggleDropdown();
  }

  function onDocumentClick(event) {
    if (
      !headerDropdown.contains(event.target) &&
      !burgerButton.contains(event.target)
    ) {
      if (headerDropdown.classList.contains('header-dropdown-opened')) {
        closeDropdown();
      }
    }
  }
  function initToggleDocumentMenu() {
    const checkbox = document.querySelectorAll('.accordeon-input');

    checkbox?.forEach((checkbox) => {
      checkbox.addEventListener('change', function () {
        if (this.checked) {
          this.closest('.accordeon').classList.add('opened');
        } else {
          this.closest('.accordeon').classList.remove('opened');
        }
      });
    });
    if (toggleableDocumentMenu) {
      const toggleMenuButton =
        toggleableDocumentMenu.querySelector('.button-toggle');
      toggleMenuButton?.addEventListener('click', (event) => {
        event.preventDefault();
        toggleableDocumentMenu.classList.toggle('closed');
      });
    }
  }
  function setActiveItem() {
    const currentUrl = window.location.pathname;
    const links = document.querySelectorAll('.accordeon-link');

    links.forEach((link) => {
      if (link.getAttribute('href') === currentUrl) {
        link.classList.add('current');
        let parent = link.closest('.accordeon');
        let parentWrapper = link.closest('.accordeon-wrapper');
        if (parentWrapper) {
          parentWrapper.classList.add('current');
        }

        while (parent) {
          parent.classList.add('opened');
          parent = parent.parentElement.closest('.accordeon');
        }
        const submenu = link
          ?.closest('.accordeon')
          ?.querySelector('.accordeon-content');
        if (submenu) {
          submenu.classList.add('opened');
        }

        // Scroll to the current item
        if (link.scrollIntoViewIfNeeded) {
          link.scrollIntoViewIfNeeded();
        } else {
          link.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }
    });
  }
  function initMenuAutoHeight() {
    const menu = document.querySelector('.content.toggleable-content');
    const anchors = document.querySelector('.anchors-wrapper');
    const footer = document.querySelector('footer');
    if (!menu || !footer) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          menu.classList.add('fill-height');
          anchors?.classList.add('fill-height');
          return;
        }

        menu.classList.remove('fill-height');
        anchors?.classList.remove('fill-height');
      },
      { root: null, threshold: 1 },
    );

    observer.observe(footer);
  }

  function onMenuItemClick(event) {
    const target = event.currentTarget;
    const parent = target?.closest('.accordeon');
    if (target.classList.contains('current')) {
      event.preventDefault();
      parent.classList.toggle('opened');
    }
  }

  function init() {
    burgerButton.addEventListener('click', onBurgerClick);
    document.addEventListener('click', onDocumentClick);
    menuItems.forEach((item) => {
      item.addEventListener('click', onMenuItemClick);
    });
    setActiveItem();
    initToggleDocumentMenu();
    initMenuAutoHeight();
  }

  return {
    init,
  };
})();
