export const DocumentPageMobileMenuHandler = (function () {
  let openedPopup = null;
  const body = document.body;

  const enablePageScroll = () => {
    body.style.overflow = '';
  };

  const closeAllPopups = () => {
    if (openedPopup) {
      openedPopup.classList.remove('opened');
      openedPopup = null;
      enablePageScroll();
    }
  };

  const togglePopup = (popup) => {
    if (openedPopup === popup) {
      closeAllPopups();
    } else {
      closeAllPopups();
      popup.classList.add('opened');
      openedPopup = popup;
    }
  };

  const initializeMobileMenu = () => {
    const contentButton = document.querySelector('.button-content-mobile');
    const pageContentButton = document.querySelector(
      '.button-page-content-mobile',
    );
    const contentList = document.querySelector('.content-list-mobile');
    const anchorsList = document.querySelector('.mobile-anchors-list');
    const menu = document.querySelector('.document-mobile-menu');
    let lastScrollTop = 0;

    if (contentButton) {
      contentButton.addEventListener('click', (e) => {
        e.stopPropagation();
        togglePopup(contentList);
        document.body.classList.toggle('no-scroll');
      });
    }
    if (pageContentButton) {
      pageContentButton.addEventListener('click', (e) => {
        e.stopPropagation();
        togglePopup(anchorsList);
      });
    }
    if (menu && window.innerWidth < 992) {
      window.addEventListener('scroll', () => {
        const currentScroll =
          window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll > lastScrollTop) {
          menu.classList.remove('hidden');
        } else {
          menu.classList.add('hidden');
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // ios fix
      });
    }
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    const select = event.currentTarget.closest('.select');
    togglePopup(select);
  };

  const selectOption = (event) => {
    const select = event.currentTarget.closest('.select');
    const selectText = select.querySelector('.select-text');
    selectText.innerText = event.currentTarget.textContent;
    closeAllPopups();
  };

  const initializeSelects = () => {
    const allSelects = document.querySelectorAll('.select:not(.tags-select)');

    allSelects.forEach((select) => {
      const button = select.querySelector('.select-button');
      const options = select.querySelectorAll('.select-option');

      button?.addEventListener('click', toggleDropdown);

      options.forEach((option) => {
        if (
          option.classList.contains('pagination-select') ||
          option.classList.contains('download-option__container')
        ) {
          return;
        }
        option.addEventListener('click', selectOption);
      });
    });
  };

  const init = () => {
    initializeMobileMenu();
    initializeSelects();
  };

  return {
    init,
  };
})();
