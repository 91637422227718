import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaginationComponent',
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    limitOptions: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
  },
  emits: ['update:page', 'update:limit'],
  computed: {
    pages() {
      const pagesArray = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pagesArray.push(i);
      }
      return pagesArray;
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('update:page', page);
      }
    },
    changeLimit(limit) {
      const newLimit = parseInt(limit);
      this.$emit('update:limit', newLimit);
    },
    toggleSelect(event) {
      event.stopPropagation();
      const select = event.currentTarget.closest('.select');
      select.classList.toggle('opened');
    },
  },
});
