<template>
  <div class="pagination">
    <div v-if="totalPages > 1" class="pagination-pages">
      <button
        class="pagination-arrow"
        :class="{ disabled: currentPage === 1 }"
        @click="changePage(currentPage - 1)"
        :disabled="currentPage === 1"
      >
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
          <path
            d="M7.16291 1.66291C7.52903 1.2968 7.52903 0.703204 7.16291 0.337087C6.7968 -0.0290291 6.2032 -0.0290291 5.83709 0.337087L0.837088 5.33709C0.47097 5.7032 0.47097 6.2968 0.837088 6.66291L5.83709 11.6629C6.2032 12.029 6.7968 12.029 7.16291 11.6629C7.52903 11.2968 7.52903 10.7032 7.16291 10.3371L3.76333 6.9375H15.875C16.3928 6.9375 16.8125 6.51777 16.8125 6C16.8125 5.48223 16.3928 5.0625 15.875 5.0625H3.76333L7.16291 1.66291Z"
            fill="white"
          />
        </svg>
      </button>

      <span
        v-for="(page, index) in pages"
        :key="index"
        :class="['pagination-page', { active: page === currentPage }]"
        @click="changePage(page)"
      >
        {{ page }}
      </span>

      <button
        class="pagination-arrow"
        :class="{ disabled: currentPage === totalPages }"
        @click="changePage(currentPage + 1)"
        :disabled="currentPage === totalPages"
      >
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
          <path
            d="M9.83709 1.66291C9.47097 1.2968 9.47097 0.703204 9.83709 0.337087C10.2032 -0.0290291 10.7968 -0.0290291 11.1629 0.337087L16.1629 5.33709C16.529 5.7032 16.529 6.2968 16.1629 6.66291L11.1629 11.6629C10.7968 12.029 10.2032 12.029 9.83709 11.6629C9.47097 11.2968 9.47097 10.7032 9.83709 10.3371L13.2367 6.9375H1.125C0.607234 6.9375 0.1875 6.51777 0.1875 6C0.1875 5.48223 0.607234 5.0625 1.125 5.0625H13.2367L9.83709 1.66291Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <div class="pagination-size">
      <div class="select">
        <button class="button select-button" @click="toggleSelect">
          <span class="select-text">{{ itemsPerPage }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon-themable select-icon"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
          >
            <path
              d="M4.21166 5.39697L7.88577 1.25292C8.11503 0.994336 7.97606 0.5 7.67411 0.5L0.325888 0.5C0.0239361 0.5 -0.115032 0.994336 0.114227 1.25292L3.78834 5.39697C3.91014 5.53434 4.08986 5.53434 4.21166 5.39697Z"
              fill="#000"
            />
          </svg>
        </button>
        <ul class="select-options">
          <li
            v-for="limit in limitOptions"
            :key="limit"
            :selected="limit === itemsPerPage"
            :value="limit"
            class="select-option pagination-select"
            @click="changeLimit(limit)"
          >
            <span> {{ limit }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./PaginationComponent.js"></script>
