import { defineComponent, ref, nextTick, watch } from 'vue';
import { EventBus, EVENT_BUS } from '../../../js/modules/eventBus';
import PaginationComponent from '../Pagination/PaginationComponent.vue';

const SearchComponent = defineComponent({
  name: 'SearchComponent',
  components: {
    PaginationComponent,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      products: this.initialData.products,
      query: '',
      results: [],
      lang: window.location.pathname.split('/')[1] || 'en',
      isOpen: this.isModalOpen,
      searchInput: ref(null),
      filter: {
        isOpen: true,
        isShort: true,
        showMore: false,
        selectedProducts: [],
      },
      isMobile: window.innerWidth < 1024,
      totalPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      limitOptions: null,
      pagination: null,
      isLoading: false,
      scrollContainer: null,
      activeIndex: -1,
    };
  },
  mounted() {
    this.handleResize = () => {
      this.isMobile = window.innerWidth < 1024;
    };

    //EventBus.on(EVENT_BUS.EVENTS.CLOSE_SEARCH, this.closeSearchHandler);
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', this.handleKeys);
    document.addEventListener('keydown', this.handleArrowNavigation);

    // Outside click
    watch(
      () => this.isOpen,
      async (newValue) => {
        if (newValue) {
          document.addEventListener('click', this.handleClickOutside);
        } else {
          document.removeEventListener('click', this.handleClickOutside);
        }
      },
    );

    //Infinity scroll
    watch(
      () => this.results.length,
      async (newLength) => {
        if (newLength > 0) {
          await nextTick();
          requestAnimationFrame(() => {
            this.scrollContainer = this.$el.querySelector(
              '.search-results-cards',
            );

            if (this.scrollContainer) {
              this.scrollContainer.addEventListener(
                'scroll',
                this.infiniteScrollHandler,
              );
            }
          });
        } else {
          if (this.scrollContainer) {
            this.scrollContainer.removeEventListener(
              'scroll',
              this.infiniteScrollHandler,
            );
            this.scrollContainer = null;
          }
        }
      },
    );
  },
  unmounted() {
    //EventBus.off(EVENT_BUS.EVENTS.CLOSE_SEARCH, this.closeSearchHandler);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.handleKeys);
    document.removeEventListener('keydown', this.handleArrowNavigation);

    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener(
        'scroll',
        this.infiniteScrollHandler,
      );
    }
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    async search(append = false) {
      if (!this.query) {
        this.results = [];
        return;
      }

      try {
        const productsParam = this.filter.selectedProducts
          .map((product) => {
            const selectedVersionOrPlatform =
              this.getSelectedVersionOrPlatform(product);
            return selectedVersionOrPlatform
              ? `${product}:${selectedVersionOrPlatform}`
              : product;
          })
          .join(',');

        const response = await fetch(
          `/api/search?query=${this.query}&products=${productsParam}&lang=${this.lang}&page=${this.currentPage}&limit=${this.itemsPerPage}`,
        );

        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();

        this.results = append ? [...this.results, ...data.items] : data.items;
        this.totalPages = data.pagination.totalPages;
        this.pagination = data.pagination;
        this.limitOptions = data.limitOptions;
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },
    searchThrottled() {
      clearTimeout(this._searchTimeout);
      this._searchTimeout = setTimeout(() => {
        this.search();
      }, 300);
    },
    clearQuery() {
      this.query = '';
      this.results = [];
      this.filter.selectedProducts = [];
      this.pagination = null;
      this.currentPage = 1;
      this.totalPages = 1;
      this.activeIndex = -1;
    },
    handleArrowNavigation(event) {
      if (!this.results.length) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (this.activeIndex < this.results.length - 1) {
          this.activeIndex++;
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (this.activeIndex > 0) {
          this.activeIndex--;
        }
      } else if (event.key === 'Enter' && this.activeIndex !== -1) {
        event.preventDefault();
        const activeElement = this.results[this.activeIndex];
        if (activeElement?.url) {
          window.location.href = activeElement.url;
        }
      }

      nextTick(() => {
        const resultItems = this.$el.querySelectorAll('.search-results-card');
        resultItems.forEach((item, index) => {
          if (index === this.activeIndex) {
            item.focus();
          }
        });
      });
    },
    toggleProductSelection(productSlug) {
      const index = this.filter.selectedProducts.indexOf(productSlug);
      const product = this.products.find((p) => p.slug === productSlug);

      if (index > -1) {
        this.filter.selectedProducts.splice(index, 1);
      } else {
        this.filter.selectedProducts.push(productSlug);
        if (product) {
          if (!product.selectedVersionOrPlatform) {
            product.selectedVersionOrPlatform =
              product.versions?.[0]?.slug ||
              product.platforms?.[0]?.slug ||
              null;
          }
        }
      }
      this.currentPage = 1;
      this.activeIndex = -1;
      this.searchThrottled();
    },
    toggleFilterOpen() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    toggleFilterHeight() {
      this.filter.isShort = !this.filter.isShort;
    },
    getSelectedVersionOrPlatform(productSlug) {
      return (
        this.products.find((p) => p.slug === productSlug)
          ?.selectedVersionOrPlatform || null
      );
    },
    selectVersionOrPlatform(productSlug, option) {
      const product = this.products.find((p) => p.slug === productSlug);
      if (product) {
        product.selectedVersionOrPlatform = option.slug;
        if (!this.filter.selectedProducts.includes(productSlug)) {
          this.filter.selectedProducts.push(productSlug);
        }
        this.searchThrottled();
      }
    },
    closeSearchHandler() {
      this.isOpen = false;
    },
    toggleSearch(e, force = false) {
      if (force) {
        this.isOpen = false;
        EventBus.emit(EVENT_BUS.EVENTS.CLOSE_SEARCH);
        this.$nextTick(() => {
          this.searchInput?.blur(); // Убираем фокус
        });
      } else {
        this.isOpen = true;
        EventBus.emit(EVENT_BUS.EVENTS.OPEN_SEARCH);
        this.$nextTick(() => {
          this.searchInput?.focus(); // Фокусируем инпут при открытии
        });
      }

      // const overlay = document.querySelector('.overlay');
      // if (this.isOpen) {
      //   overlay.classList.add('active');
      // } else {
      //   overlay.classList.remove('active');
      // }
    },
    resetFilter() {
      this.filter.selectedProducts = [];
      this.products.forEach(
        (product) => (product.selectedVersionOrPlatform = null),
      );
    },
    async applyFilter() {
      await this.search();
      this.filter.isOpen = false;
    },
    handleClickOutside(event) {
      if (this.$el.contains(event.target)) {
        return;
      }
      this.toggleSearch(event, true);
    },
    async handleKeys(event) {
      if (event.key === 'Escape') {
        this.toggleSearch(event, true);
      }
    },
    async fetchResults(page) {
      this.currentPage = page;
      await this.search();
    },
    async changeLimit(newLimit) {
      this.itemsPerPage = newLimit;
      this.currentPage = 1;
      await this.search();
    },
    infiniteScrollHandler() {
      if (
        !this.scrollContainer ||
        this.isLoading ||
        this.currentPage >= this.totalPages
      )
        return;

      requestAnimationFrame(() => {
        const { scrollTop, scrollHeight, clientHeight } = this.scrollContainer;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          this.isLoading = true;
          this.currentPage++;
          this.search(true).finally(() => {
            this.isLoading = false;
          });
        }
      });
    },
  },
  watch: {
    query(newQuery) {
      if (newQuery) {
        this.searchThrottled();
        this.activeIndex = -1;
      } else {
        this.results = [];
      }
    },
    filter: {
      handler(newFilter) {
        if (newFilter.selectedProducts) {
          this.activeIndex = -1;
        }
      },
      deep: true,
    },
  },
});
export default SearchComponent;
