export const TranslationsModule = (function () {
  const translationsData = window.__TRANSLATIONS__;
  function initializeTheme() {
    document.querySelectorAll('[data-translate]').forEach((el) => {
      const key = el.getAttribute('data-translate');
      const translation = getNestedTranslation(translationsData, key);

      if (translation) {
        el.textContent = translation;
      } else {
        console.warn(`Translation for key '${key}' not found`);
      }
    });
  }
  function getNestedTranslation(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  return {
    init: initializeTheme,
  };
})();
