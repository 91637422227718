export const SelectModule = (function () {
  const body = document.body;

  const toggleDropdown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const select = event.currentTarget.closest('.select');
    if (select.classList.contains('tags-select')) {
      closeAllDropdownsExcept(select);
    } else {
      closeAllDropdowns();
      select.classList.toggle('opened');
    }
  };

  const updateUrlAndReload = (index, value) => {
    const currentUrl = new URL(window.location.href);
    const pathParts = currentUrl.pathname.split('/');
    pathParts[index] = value.toLowerCase();
    currentUrl.pathname = pathParts.join('/');
    window.location.href = currentUrl.toString();
  };

  const selectOption = (event) => {
    const target = event.currentTarget;

    event.stopPropagation();
    event.preventDefault();

    const select = target.closest('.select');
    if (!select) return;

    const selectText = select.querySelector('.select-text');
    const content = target.textContent.trim();

    if (select.classList.contains('language-select')) {
      updateUrlAndReload(1, content);
      saveLanguage(content);
    } else if (select.classList.contains('select-version')) {
      const version = target.getAttribute('data-value');
      updateUrlAndReload(3, version);
    } else {
      selectText.innerText = content.toUpperCase();
    }
  };

  const saveLanguage = (content) => {
    localStorage.setItem('language', content);
  };

  const closeAllDropdowns = () => {
    document.querySelectorAll('.select').forEach((select) => {
      select.classList.remove('opened');
    });
  };

  const closeAllDropdownsExcept = (exceptSelect) => {
    document.querySelectorAll('.select').forEach((select) => {
      if (select !== exceptSelect) {
        select.classList.remove('opened');
      }
    });
  };

  const closeDropdownFromOutside = (event) => {
    if (!event.target.closest('.select')) {
      closeAllDropdowns();
    }
  };

  const initializeSelects = () => {
    const allSelects = document.querySelectorAll('.select:not(.tags-select)');

    allSelects.forEach((select) => {
      const button = select.querySelector('.select-button');
      const options = select.querySelectorAll('.select-option');

      button?.addEventListener('click', toggleDropdown);

      options.forEach((option) => {
        if (
          option.classList.contains('pagination-select') ||
          option.classList.contains('download-option__container')
        ) {
          return;
        }
        option.addEventListener('click', selectOption);
      });
    });
  };

  const initializeTagsSelect = () => {
    const select = document.querySelector('.tags-select');
    if (!select) return;

    const checkboxes = select.querySelectorAll(
      '.select-option input[type="checkbox"]',
    );
    const selectOptions = select.querySelector('.select-options');
    const searchInput = select.querySelector('[data-js="search-input"]');
    const clearButton = select.querySelector('[data-js="clear-button"]');

    const filterOptions = () => {
      const searchText = searchInput.value.toLowerCase();
      checkboxes.forEach((checkbox) => {
        const label = checkbox.parentElement.textContent.toLowerCase();
        const liElement = checkbox.closest('li');
        liElement.style.display = label.includes(searchText) ? '' : 'none';
      });
      clearButton.classList.toggle('active', searchText.length > 0);
    };

    const clearSearch = () => {
      searchInput.value = '';
      filterOptions();
      clearButton.classList.remove('active');
      updateSelectText();
    };

    document.addEventListener('click', (e) => {
      if (!select.contains(e.target)) {
        closeTagsDropdown();
      }
    });

    searchInput.addEventListener('input', filterOptions);

    clearButton.addEventListener('click', () => {
      clearSearch();
      closeTagsDropdown();
    });

    searchInput.addEventListener('focus', () => {
      if (!selectOptions.classList.contains('active')) {
        selectOptions.classList.add('active');
        select.classList.add('opened');
      }
      clearButton.classList.add('active');
    });

    updateSelectText();
  };

  const init = () => {
    initializeSelects();
    initializeTagsSelect();
    body.addEventListener('click', closeDropdownFromOutside);
  };

  return {
    init: init,
  };
})();
