import { createApp, h } from 'vue';
import { DropdownModule } from './modules/menu.js';
import { SelectModule } from './modules/select.js';
import { DocumentPageMobileMenuHandler } from './modules/mobile.js';
import { ClipboardHandlerModule } from './modules/clipboard.js';
import {
  AnchorListHandlerModule,
  AnchorScrollHandlerModule,
} from './modules/anchors.js';
import { TranslationsModule } from './modules/translations.js';
import { DownloadModule } from './modules/download.js';
import { ThemeModule } from './modules/theme.js';
import { EventsModule } from './modules/events.js';
import SearchComponent from '../vue/components/Search/SearchComponent.vue';

const modules = [
  DropdownModule,
  SelectModule,
  DocumentPageMobileMenuHandler,
  ClipboardHandlerModule,
  AnchorListHandlerModule,
  AnchorScrollHandlerModule,
  DownloadModule,
  ThemeModule,
  EventsModule,
  TranslationsModule,
];

document.addEventListener('DOMContentLoaded', function () {
  const searchComponentElement = document.getElementById('vue-app');
  if (searchComponentElement) {
    const isOpen =
      searchComponentElement.getAttribute('data-is-open') === 'true';
    createApp({
      render: () =>
        h(SearchComponent, {
          initialData: window.__VUE_SEARCH_DATA__,
          isModalOpen: isOpen,
        }),
    }).mount('#vue-app');
  } else {
    console.warn('Did not find search component element #vue-app');
  }
  const logoLink = document.querySelector('.navbar-logo-link');
  if (logoLink) {
    logoLink.addEventListener('click', (event) => {
      const pathnameChunks = window.location.pathname.split('/');
      if (pathnameChunks.length <= 2) {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }
  modules.forEach((module) => {
    module.init();
  });
});
