export const DownloadModule = (function () {
  async function downloadFile(fileType) {
    const fallbackFilename = 'download.' + fileType;
    const controller = new AbortController();
    const response = await fetch('/v1/api/file?download=' + fileType, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: window.location.href,
      }),
      signal: controller.signal,
    });

    if (!response.ok) {
      console.error('Error from server:', response.statusText);
      return;
    }

    const disposition = response.headers.get('Content-Disposition');
    const filename = disposition
      ? extractFilename(disposition)
      : fallbackFilename;

    const blob = await response.blob();
    await saveFileFromResponse(blob, filename);
  }

  function initializeDownload() {
    let isDownloadingPdf = false;
    let isDownloadingWord = false;
    const downloadPdfButton = document.querySelector('[data-format="pdf"]');
    const downloadWordButton = document.querySelector('[data-format="word"]');

    if (downloadPdfButton) {
      downloadPdfButton.addEventListener('click', async () => {
        if (isDownloadingPdf) {
          return;
        }
        isDownloadingPdf = true;
        toggleSpinner(downloadPdfButton, true);

        try {
          await downloadFile('pdf');
        } finally {
          toggleSpinner(downloadPdfButton, false);
          isDownloadingPdf = false;
        }
      });
    }

    if (downloadWordButton) {
      downloadWordButton.addEventListener('click', async () => {
        if (isDownloadingWord) {
          return;
        }
        isDownloadingWord = true;
        toggleSpinner(downloadWordButton, true);

        try {
          await downloadFile('docx');
        } finally {
          toggleSpinner(downloadWordButton, false);
          isDownloadingWord = false;
        }
      });
    }
  }
  function toggleSpinner(button, isActive) {
    const container = button.closest('.download-option__container');
    const spinner = container ? container.querySelector('.spinner') : null;
    if (spinner) {
      if (isActive) {
        spinner.classList.add('active');
        container.classList.add('loading');
      } else {
        spinner.classList.remove('active');
        container.classList.remove('loading');
      }
    }
  }

  function extractFilename(disposition) {
    const FILENAME_REGEX = /filename="?([^"]+)"?/;
    const filenameMatch = disposition.match(FILENAME_REGEX);
    return filenameMatch?.[1] || defaultFilename;
  }

  function createDownloadLink(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURIComponent(filename));
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const saveFileFromResponse = async (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    try {
      createDownloadLink(url, filename);
    } finally {
      window.URL.revokeObjectURL(url);
    }
  };

  return {
    init: initializeDownload,
  };
})();
