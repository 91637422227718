import { reactive } from 'vue';

export const EventBus = reactive({
  events: {},
  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  },
  emit(event, payload) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => callback(payload));
    }
  },
  off(event, callback) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((cb) => cb !== callback);
    }
  },
});

export const EVENT_BUS = {
  EVENTS: {
    CLOSE_SEARCH: 'close-search',
    OPEN_SEARCH: 'open-search',
    OPEN_HEADER_MENU: 'open-header-menu',
    CLOSE_HEADER_MENU: 'close-header-menu',
  },
};
