export const ThemeModule = (function () {
  function applyTheme(theme) {
    document.documentElement.classList.remove('dark-theme', 'light-theme');
    document.documentElement.classList.add(
      theme === 'dark' ? 'dark-theme' : 'light-theme',
    );

    document.querySelectorAll('.button-theme').forEach((button) => {
      button.classList.toggle('button-theme-yellow', theme === 'dark');
    });

    localStorage.setItem('theme', theme);
  }

  function toggleTheme() {
    document.dispatchEvent(new Event('theme-change'));
    const currentTheme = document.documentElement.classList.contains(
      'dark-theme',
    )
      ? 'dark'
      : 'light';
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';

    applyTheme(newTheme);
  }

  function initializeTheme() {
    const savedTheme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;
    let theme = savedTheme || (prefersDark ? 'dark' : 'light');

    applyTheme(theme);

    document.querySelectorAll('.button-theme').forEach((button) => {
      button.removeEventListener('click', toggleTheme);
      button.addEventListener('click', toggleTheme);
    });

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        if (!localStorage.getItem('theme')) {
          applyTheme(e.matches ? 'dark' : 'light');
        }
      });

    setCodeTheme();
    document.addEventListener('theme-change', setCodeTheme);
  }
  function setCodeTheme() {
    const isDark = document.documentElement.classList.contains('dark-theme');

    document
      .querySelectorAll('link[rel="stylesheet"][data-hljs-theme]')
      .forEach((link) => link.remove());

    const themeLink = document.createElement('link');
    themeLink.rel = 'stylesheet';
    themeLink.setAttribute('data-hljs-theme', 'true');

    themeLink.href = isDark
      ? 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/github-dark-dimmed.min.css'
      : 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/base16/github.min.css';

    document.head.appendChild(themeLink);
  }

  return {
    init: initializeTheme,
  };
})();
