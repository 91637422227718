export const AnchorListHandlerModule = (function () {
  const mobileHeadingList = document.querySelector('.mobile-anchors-list');
  const desktopHeadingList = document.querySelector('.anchors-list');
  const desktopHeadingTitle = document.querySelector('.anchors-title');
  const mobileHeadingButton = document.querySelector(
    '.button-page-content-mobile',
  );
  const headings = document.querySelectorAll(
    '.article-content h2, .article-content h3, .article-content h4 .article-content h5 .article-content h6',
  );

  const populateHeadingList = (headingList) => {
    headingList.innerHTML = '';
    if (headings.length === 0) {
      desktopHeadingTitle?.classList.add('hidden');
      desktopHeadingList?.classList.add('hidden');
      mobileHeadingButton?.classList.add('hidden');
    }
    headings.forEach((heading) => {
      const id = heading.id;
      if (!id) return; // Skip headings without an id
      const listItem = document.createElement('li');
      listItem.classList.add('anchors-list-item');
      const anchor = document.createElement('a');
      anchor.href = `#${id}`;
      anchor.classList.add(heading.localName);
      anchor.innerText = heading.innerText;
      anchor.classList.add('anchors-list-link');

      listItem.appendChild(anchor);
      headingList.appendChild(listItem);
    });

    if (headingList === mobileHeadingList) {
      headingList.querySelectorAll('a').forEach((anchor) => {
        anchor.addEventListener('click', () => {
          mobileHeadingList.classList.remove('opened');
        });
      });
    }
  };

  const updateHeadingLists = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 992 && desktopHeadingList) {
      populateHeadingList(desktopHeadingList);
    } else if (mobileHeadingList) {
      populateHeadingList(mobileHeadingList);
    }
  };

  const init = () => {
    updateHeadingLists();
    window.addEventListener('resize', updateHeadingLists);
  };

  return {
    init,
  };
})();

export const AnchorScrollHandlerModule = (function () {
  const scrollToTopButton = document.querySelector('.anchors-scroll-top');

  const scrollToTop = (event) => {
    event.preventDefault();

    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const initializeEventListeners = () => {
    if (scrollToTopButton) {
      scrollToTopButton.addEventListener('click', scrollToTop);
    }
  };

  const init = () => {
    initializeEventListeners();
  };

  return {
    init,
  };
})();
